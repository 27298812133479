.HomeS4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.55vw 0;
  width: 100%;
  background-color: #fff7f7;
  position: relative;
}
.HS4C1 {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding: 10vw 2vw;
  padding-bottom: 0;
  background-color: #fff7f7;
  position: relative;
}
.HS4C1P1 {
  text-align: center;
  font-family: Switzer;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HS4C1P1 span {
  color: #ff4a4a;
}
.HS4C1P2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 30vw;
}

.HomeS4P2 {
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 20vw;
}
.HomeS4P2::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 74, 74, 0.5) 360deg);
  animation: rotate 5s linear infinite;
  z-index: 1;
}

.HomeS4P2::after {
  content: "";
  position: absolute;
  width: 15%;
  height: 15%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 74, 74, 1) 360deg);
  animation: rotate-reverse 5s linear infinite;
  z-index: 2;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HomeS4P2Content {
  position: relative;
  z-index: 5;
  text-align: center;
  width: 100%;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.HomeS4P2Number {
  z-index: 4;
}

.HomeS4P2Text {
  position: absolute;
  top: 3vw;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  animation: fadeInOut 5s infinite;
  width: 100%;
}

/*  */
.HS4C1P2Box1,
.HS4C1P2Box3 {
  position: absolute;
  bottom: 45%;
}
.HS4C1P2Box4,
.HS4C1P2Box2 {
  position: absolute;
  top: -20%;
}
.HS4C1P2Box1 {
  left: 0;
}
.HS4C1P2Box2 {
  left: 25%;
}
.HS4C1P2Box3 {
  left: 52.5%;
}
.HS4C1P2Box4 {
  right: 0;
}
.HS4C1P2Box1C,
.HS4C1P2Box3C {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  top: -48%;
  left: 50%;
  width: 100%;
}
.HS4C1P2ImgL1 {
  width: 10vw;
}
.HS4C1P2Box1CText {
  margin-top: 0.2vw;
  margin-left: -3.5vw;
}
.HS4C1P2Box2C {
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: -39%;
  left: 50%;
  width: 100%;
}
.HS4C1P2ImgL2 {
  width: 6vw;
}
.HS4C1P2Box2CText {
  margin-left: 1vw;
  margin-bottom: -0.2vw;
}
.HS4C1P2Box3CText {
  margin-left: -3.5vw;
  margin-top: 0.2vw;
}
.HS4C1P2Box4C {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  bottom: -35%;
  left: 8%;
  width: 100%;
}
.HS4C1P2ImgL4 {
  width: 8vw;
}
.HS4C1P2Box4CText {
  margin-left: 0.3vw;
  margin-bottom: -0.2vw;
}
.HS4C1P2Box1CText,
.HS4C1P2Box2CText,
.HS4C1P2Box3CText,
.HS4C1P2Box4CText,
.HomeS4P2Number {
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.HS4C1P2Box1CText span,
.HS4C1P2Box2CText span,
.HS4C1P2Box3CText span,
.HS4C1P2Box4CText span {
  color: #ff4a4a;
}

/*  */
/*  */
.HS4C2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vw;
  padding-bottom: 3vw;
  width: 79%;
}
.HS4C2P1 {
  color: #19191b;
  font-family: Switzer;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HS4C2P1 span {
  color: #ff4a4a;
}
.HS4C2P2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.HS4C2P2LP2 {
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  height: 25vw;
}

.HS4C2P2LP2::before {
  content: "";
  position: absolute;
  width: 160%;
  height: 160%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 74, 74, 0.5) 360deg);
  animation: rotate 5s linear infinite;
  z-index: 1;
}

/* .HS4C2P2LP2::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 74, 74, 1) 360deg);
  animation: rotate-reverse 5s linear infinite;
  z-index: 1;
} */

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HS4C2P2LP2Content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.HS4C2P2LP2Img {
  width: 30vw;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.HS4C2P2LP2Text {
  position: absolute;
  top: 10%;
  left: 27%;
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  animation: fadeInOut 5s infinite;
}
.HS4C2P2R {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.7vw;
}
.HS4C2P2RBoxC {
  display: flex;
  padding: 1.4vw;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 0.6vw;
  background: #fff7f7;
  box-shadow: 0px 0px 14.286px 0px rgba(0, 0, 0, 0.1);
  width: 40vw;
  gap: 1.2vw;
}
.P2RBoxCL {
  display: flex;
  padding: 1vw 1.5vw;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vw;
  color: #fff;
  text-align: center;
  font-family: Switzer;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.P2RBoxCR {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2vw;
}
.P2RBoxCR div {
  color: #263238;
  font-family: Switzer;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.P2RBoxCR span {
  color: #ff4a4a;
}
@media (max-width: 768px) {
  .HS4C1P1 {
    font-size: 4.65vw;
  }
  .HS4C1P2 {
    height: auto;
    flex-direction: column;
  }
  .HS4C1P2Box1,
  .HS4C1P2Box2,
  .HS4C1P2Box3,
  .HS4C1P2Box4 {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .HS4C1P2Box1C,
  .HS4C1P2Box2C,
  .HS4C1P2Box3C,
  .HS4C1P2Box4C {
    position: relative;
    top: -7vw;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .HomeS4P2,
  .HS4C2P2LP2 {
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 70vw;
  }
  .HS4C2P2LP2::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
  }
  .HS4C2P2LP2Img {
    width: 70vw;
  }
  .HS4C2P2LP2Text {
    font-size: 4.65vw;
  }
  .HomeS4P2Text {
    font-size: 4.65vw;
    top: 10vw;
  }
  .HS4C1P2Box1CText,
  .HS4C1P2Box2CText,
  .HS4C1P2Box3CText,
  .HS4C1P2Box4CText,
  .HomeS4P2Number {
    font-size: 4.65vw;
  }
  .HS4C1P2Box1CText,
  .HS4C1P2Box2CText,
  .HS4C1P2Box3CText,
  .HS4C1P2Box4CText {
    margin-left: 0;
  }
  .HS4C1P2ImgL1,
  .HS4C1P2ImgL2,
  .HS4C1P2ImgL3,
  .HS4C1P2ImgL4 {
    width: 1.5vw;
  }
  .HS4C2P1 {
    font-size: 4.65vw;
    padding: 15vw;
  }
  .HS4C2P2 {
    flex-direction: column;
    gap: 15vw;
  }
  .HS4C2P2R {
    gap: 5.8vw;
  }
  .HS4C2P2RBoxC {
    flex-direction: column;
    width: 75vw;
    padding: 4.65vw;
    gap: 5.8vw;
  }
  .P2RBoxCR div,
  .P2RBoxCL {
    font-size: 4.65vw;
  }
  .P2RBoxCL {
    justify-items: center;
    align-self: center;
    padding: 3vw 5vw;
    border-radius: 1.7vw;
  }
  .P2RBoxCR {
    gap: 5.8vw;
  }
}
