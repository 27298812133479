.EnHeaderContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 3vh 6vw;
  align-items: center;
  background: #fff7f7;
  position: fixed;
  width: 100vw;
  z-index: 100000000000000;
}
.EnHeaderR {
  display: flex;
  gap: 2vw;
}
.EnHeaderR a,
.EnHeaderR a:active,
.EnHeaderR a:visited {
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.LinkSpan span {
  color: #ff4a4a;
}
.EnHeaderRL {
  width: 0.1vw;
  height: 2vw;
  background-color: rgba(255, 74, 74, 0.5);
}
.EnHeaderRC {
  display: flex;
  align-items: center;
  gap: 10px;
}
.EnHeaderRF {
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5vw;
  height: 1.5vw;
}
.EnHeaderRF::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 74, 74, 0.5) 360deg);
  animation: rotate 5s linear infinite;
  z-index: 1;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  .EnHeaderContainer {
    grid-template-columns: 0.9fr 1fr;
    padding: 3vw 6vw;
  }
  .EnHeaderL img {
    width: 35vw;
  }
  .EnHeaderR a,
  .EnHeaderR a:active,
  .EnHeaderR a:visited {
    font-size: 3.25vw;
  }
  .EnHeaderRF {
    width: 4vw;
    height: 4vw;
  }
  .EnHeaderRL {
    width: 0.2vw;
    height: 5vw;
    background-color: rgba(255, 74, 74, 0.5);
    margin-top: 1vw;
  }
}
