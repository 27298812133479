.HomeS1New {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  padding: 12vh 10vw;
  flex-wrap: wrap;
  padding-top: 15vw;
}
.HomeS1NewFirst {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15vw;
}
.HomeS1NewP1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
}

.HomeS1NewP1V1Img {
  width: 12vw;
}
.HomeS1NewP1V2 {
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.HomeS1NewP1V3 {
  display: flex;
  gap: 1vw;
}
.HomeS1NewP1V3Img {
  width: 8vw;
}
.HomeS1NewP2 {
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 30vw;
}

.HomeS1NewP2::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  border-radius: 50%;
  background: conic-gradient(from 0deg at 50% 50%, rgba(255, 74, 74, 0.5) 180deg, rgba(255, 255, 255, 0.5) 360deg);
  animation: rotate 5s linear infinite;
  z-index: -2;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HomeS1NewP2ContentP1 {
  z-index: 10000;
}

.HomeS1NewP2ImgMain {
  width: 20vw;
  margin-top: 4vw;
}
.HomeS1NewP2ContentP2 {
  z-index: 5;
}
.HomeS1NewP2ImgLines {
  position: absolute;
  width: 33vw;
  top: -3vw;
  left: -1.5vw;
}

@keyframes fadeInOutWidth {
  0% {
    opacity: 0;
    width: 0;
  }
  10% {
    opacity: 1;
    width: 35vw;
  }
  100% {
    width: 35vw;
  }
}

.HomeS1NewP2Last {
  position: absolute;
  z-index: 6;
}

.HomeS1NewP2Img {
  width: 33vw;
  animation: fadeInOutWidth 5s infinite;
}

/*  */
.HomeS1NewP3 {
  text-align: center;
  font-family: Switzer;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
}

.scrolling-content-new {
  display: inline-flex;
  align-items: center;
  animation: scrollLeft 8s linear infinite;
  white-space: nowrap;
  color: black;
}

.dot {
  display: inline-block;
  width: 0.5vw;
  height: 0.5vw;
  background-color: currentColor;
  border-radius: 50%;
  margin: 0 0.5vw;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
    color: black;
  }
  50% {
    color: #ff4a4a;
  }
  100% {
    transform: translateX(-100%);
    color: #ff4a4a;
  }
}
@media (max-width: 575px) {
  .HomeS1NewFirst {
    flex-direction: column-reverse;
    padding-top: 25vw;
  }
  .HomeS1New {
    padding: 6vh 10vw;
    padding: 8vw 0;
    width: 100%;
  }

  .HomeS1NewP2Text {
    font-size: 5vw;
    left: 20%;
  }
  .HomeS1NewP1 {
    gap: 2vh;
  }
  .HomeS1NewP1V1Img {
    width: 20vw;
  }
  .HomeS1NewP1V2 {
    font-size: 4vw;
  }
  .HomeS1NewP1V3 {
    flex-direction: column;
  }
  .HomeS1NewP1V3Img {
    width: 30vw;
  }
  .HomeS1NewP3 {
    font-size: 4vw;
  }
  .scrolling-content-new {
    animation: scrollLeft 12s linear infinite;
  }
  /*  */
  /*  */
  /*  */
  .HomeS1NewP2 {
    width: 60vw;
    height: 60vw;
  }
  .HomeS1NewP2ContentP1 {
    z-index: 10000;
  }

  .HomeS1NewP2ImgMain {
    width: 40vw;
    margin-top: 10vw;
  }
  .HomeS1NewP2ContentP2 {
    z-index: 5;
  }
  .HomeS1NewP2ImgLines {
    position: absolute;
    width: 65vw;
    top: -5vw;
    left: -2vw;
  }

  @keyframes fadeInOutWidth {
    0% {
      opacity: 0;
      width: 0;
    }
    10% {
      opacity: 1;
      width: 70vw;
    }
    100% {
      width: 70vw;
    }
  }

  .HomeS1NewP2Last {
    position: absolute;
    z-index: 6;
  }

  .HomeS1NewP2Img {
    animation: fadeInOutWidth 5s infinite;
  }
}
