.HomeS2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.4vw;
  width: 100%;
  background: #fff7f7;
  padding: 3.4vw 0;
}
.HomeS2T1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: center;
  padding: 2vw 5vw;
  background: #fff7f7;
}
.HomeS2P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  gap: 1.75vw;
}
/*  */
.HomeS2P1V1 {
  color: #000;
  font-family: Switzer;
  font-size: 2.24vw; /* EQUAL 32Px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}
.HomeS2P1V1 span {
  color: #ff4a4a;
}
/*  */

.HomeS2P1V2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  text-align: center;
}
/*  */

.HomeS2P1V2T {
  color: #000;
  font-family: Switzer;
  font-size: 1.7vw; /* EQUAL 32Px */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}
.HomeS2P1V2T span {
  color: #ff4a4a;
}
/*  */
.HomeS2P1V3 {
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.42vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
/*  */
/*  */
.HomeS2P2 {
  padding-left: 3vw;
}
.HomeS2P2Img {
  width: 17vw;
}
.HomeS2N2Img,
.HomeS2NImg {
  padding-top: 1vh;
}
.HomeS2T2 {
  width: 75vw;
  height: 0.1vw;
  background-color: #ff4a4a33;
}

/*  */
/*  */
/*  */
.HomeS2T3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2vw;
}
.HomeS2Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 20vw;
  padding: 3vw 1.5vw;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  gap: 1vw;
}
.HS2BP1 img {
  width: 3vw;
}
.HS2BP2,
.HS2BP1N {
  color: #ff4a4a;
  text-align: center;
  font-family: Switzer;
  font-size: 1.42vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.HS2BP3S {
  width: 20vw;
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.35vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.HomeS2T4 {
  display: flex;
  flex-direction: column;
  gap: 1.75vw;
}
.HomeS2T4C1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.75vw;
}
.HomeS2T4C1D1 {
  color: #19191b;
  text-align: center;
  font-family: Switzer;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HomeS2T4C1D2 {
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.75vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.HomeS2T4C1D1 span,
.HomeS2T4C1D2 span {
  color: #ff4a4a;
}
.HomeS2T4C2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2vw;
}
.HomeS2P2Mob {
  display: none;
}
@media (max-width: 768px) {
  .HomeS2P2Mob {
    display: block;
  }
  .web.HomeS2P2 {
    display: none;
  }
  .HomeS2T1 {
    grid-template-columns: 1fr 0fr;
  }
  .HomeS2P1V1 {
    font-size: 4.65vw;
  }
  .HomeS2P2Img {
    width: 70vw;
  }
  .HomeS2P1V2 {
    flex-direction: column-reverse;
    gap: 5vw;
  }
  .HomeS2P1 {
    width: 90vw;
    gap: 3vw;
  }
  .HomeS2P1V2T,
  .HomeS2P1V3 {
    font-size: 4.65vw;
    width: 90%;
  }
  /*  */
  .HomeS2T3 {
    flex-direction: column;
  }
  .HomeS2Box {
    width: 70vw;
    padding: 4.65vw 1.5vw;
    gap: 5vw;
  }
  .HS2BP1 img {
    width: 15vw;
  }
  .HS2BP2 {
    font-size: 4.65vw;
  }
  .HS2BP3S {
    font-size: 4.65vw;
    width: 90%;
  }

  /*  */
  .HomeS2T4 {
    padding-top: 5vw;
  }
  .HomeS2T4C2 {
    flex-direction: column;
  }
  .HomeS2T4C1D1 {
    font-size: 4.65vw;
  }
  .HomeS2T4C1D2 {
    font-size: 4.65vw;
    width: 80%;
    padding-bottom: 5vw;
  }
  .HS2BP1N {
    font-size: 4.65vw;
  }
}
