.EnglishFooterMain {
  padding: 6vh 5vw;
  background: #fff7f7;
  display: flex;
  flex-direction: column;
  gap: 6vh;
}

.FooterS1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.FooterS1P2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  margin-left: -5vw;
}
.FooterS1P2V1 {
  color: #ff4a4a;
  font-family: Switzer;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-decoration: underline;
}
.FooterS1P2V2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vh;
}
.FooterS1P3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.FooterS1P1 img {
  margin-left: 5vw;
  width: 12vw;
}
.FooterS1P2Img {
  width: 2.5vw;
}
.FooterS1P3Img {
  width: 8vw;
}
.FooterS2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3c3c;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}
.FooterS2 span {
  color: #000;
  padding-left: 0.5vw;
}
@media (max-width: 575px) {
  .FooterS1 {
    grid-template-columns: 1fr;
  }
  .FooterS1P1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .FooterS1P1 img {
    width: 50vw;
    padding-bottom: 2vh;
  }
  .FooterS1P2 {
    margin: 0;
  }
  .FooterS1P2V1 {
    font-size: 4.5vw;
  }
  .FooterS1P2Img {
    width: 15vw;
    padding-bottom: 2vh;
  }
  .FooterS1P2V2Mob {
    display: flex;
    gap: 2vh;
    flex-direction: column;
  }
  .FooterS1P3Img {
    width: 30vw;
  }
  .FooterS2 {
    font-size: 4.5vw;
  }
  .FooterS2 span {
    padding: 0;
  }
}
