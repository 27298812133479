/*  */
/*  */
/*  */
/*  */
/*  */

.HomeS5 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-content: center;
  padding: 4vh 20vw;
  padding-top: 0;
  padding-right: 5vw;
}
.HomeS5P1,
.HomeS2P1T,
.HomeS5P1B {
  display: flex;
  flex-direction: column;
  gap: 1.75vw;
}
.HomeS5P1B,
.HomeS5P1 {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.HomeS5P1B div,
.HomeS5P1B div,
.HomeS5P1TM2 {
  color: #000;
  font-family: Switzer;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 67%;
}
.HomeS5P1B a,
.HomeS5P1B a:active,
.HomeS5P1B a:visited {
  text-decoration: none;
  color: #000;
  font-family: Switzer;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.HomeS5P2 {
  position: relative;
}
.HomeS5P2Img {
  width: 15vw;
}
.ScanYellow {
  position: absolute;
  left: -25%;
  top: 18%;
  width: 14vw;
}
.HomeS5P1T1 {
  color: #000;
  font-family: Switzer;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.HomeS5P1TM,
.HomeS5P1TM {
  color: #1e1e1e;
  font-family: Switzer;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HomeS5P1TM span {
  color: #ff4a4a;
}
.HomeS5P1TM3 {
  color: #ff4a4a;
  font-family: Switzer;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (max-width: 575px) {
  .HomeS5 {
    display: flex;
    flex-direction: column;
    padding: 3vw 7vw;
  }
  .HomeS5P1TM2 {
    font-size: 4.5vw;
    width: 100%;
    text-align: center;
  }
  .HomeS5P1TM {
    font-size: 4.5vw;
    text-align: center;
  }
  .HomeS5P1TM3 {
    font-size: 4.65vw;
    padding-top: 5.8vw;
  }
  .HomeS5P1T1 {
    font-size: 4.5vw;
    padding-top: 1vh;
  }
  .HomeS5P2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HomeS5P2Img {
    width: 50vw;
  }
  .ScanYellow {
    left: 0%;
    width: 40vw;
  }
  .HomeS5P1B {
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }

  .HomeS5P1B div {
    color: #000;
    font-family: Switzer;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: auto;
  }
  .HomeS5P1B a,
  .HomeS5P1B a:active,
  .HomeS5P1B a:visited {
    font-size: 4.5vw;
  }
}
