/*  */
/*  */
/*  */
/*  */
.HomeS3 {
  display: flex;
  flex-direction: column;
  padding: 1vw 4vw;
  gap: 3.4vw;
}
.HomeS3P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
}
.HomeS3P1B1 {
  color: #19191b;
  text-align: center;
  font-family: Switzer;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HomeS3P1B2B {
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.75vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.HomeS3P1B2B span {
  color: #ff4a4a;
}
.HomeS3P2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2vw;
}
.HomeS3Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 20vw;
  /* height: 15vw; */
  padding: 3vw 1.5vw;
  border-radius: 8px;
  background: #fff7f7;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  gap: 1vw;
}
.HS3BP2,
.HS3BP1N {
  color: #ff4a4a;
  text-align: center;
  font-family: Switzer;
  font-size: 1.42vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.HS3BP3S {
  width: 20vw;
  color: #000;
  text-align: center;
  font-family: Switzer;
  font-size: 1.35vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .HomeS3P1B1 {
    font-size: 4.65vw;
  }
  .HomeS3P1B2B {
    font-size: 4.65vw;
  }
  .HomeS3P2 {
    flex-direction: column;
    padding-top: 5vw;
  }
  .HomeS3Box {
    width: 70vw;
    padding: 4.65vw 1.5vw;
    gap: 5vw;
  }
  .HS3BP2 {
    font-size: 4.65vw;
  }
  .HS3BP3S {
    font-size: 4.65vw;
    width: 90%;
  }
}
